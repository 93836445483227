<template>
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.7661 5.75H8.51611C7.81992 5.75 7.15224 6.02656 6.65996 6.51884C6.16767 7.01113 5.89111 7.67881 5.89111 8.375V34.625C5.89111 35.3212 6.16767 35.9889 6.65996 36.4812C7.15224 36.9734 7.81992 37.25 8.51611 37.25H34.7661C35.4623 37.25 36.13 36.9734 36.6223 36.4812C37.1146 35.9889 37.3911 35.3212 37.3911 34.625V8.375C37.3911 7.67881 37.1146 7.01113 36.6223 6.51884C36.13 6.02656 35.4623 5.75 34.7661 5.75ZM34.7661 34.625H8.51611V8.375H34.7661V34.625ZM27.8197 17.1786L23.4967 21.5L27.8197 25.8214C27.9417 25.9433 28.0384 26.0881 28.1044 26.2474C28.1704 26.4068 28.2043 26.5775 28.2043 26.75C28.2043 26.9225 28.1704 27.0932 28.1044 27.2526C28.0384 27.4119 27.9417 27.5567 27.8197 27.6786C27.6978 27.8005 27.553 27.8973 27.3937 27.9633C27.2343 28.0293 27.0636 28.0632 26.8911 28.0632C26.7187 28.0632 26.5479 28.0293 26.3886 27.9633C26.2292 27.8973 26.0845 27.8005 25.9625 27.6786L21.6411 23.3555L17.3197 27.6786C17.1978 27.8005 17.053 27.8973 16.8937 27.9633C16.7343 28.0293 16.5636 28.0632 16.3911 28.0632C16.2187 28.0632 16.0479 28.0293 15.8886 27.9633C15.7292 27.8973 15.5845 27.8005 15.4625 27.6786C15.3406 27.5567 15.2438 27.4119 15.1778 27.2526C15.1119 27.0932 15.0779 26.9225 15.0779 26.75C15.0779 26.5775 15.1119 26.4068 15.1778 26.2474C15.2438 26.0881 15.3406 25.9433 15.4625 25.8214L19.7856 21.5L15.4625 17.1786C15.2162 16.9323 15.0779 16.5983 15.0779 16.25C15.0779 15.9017 15.2162 15.5677 15.4625 15.3214C15.7088 15.0751 16.0428 14.9368 16.3911 14.9368C16.7394 14.9368 17.0734 15.0751 17.3197 15.3214L21.6411 19.6445L25.9625 15.3214C26.0845 15.1995 26.2292 15.1027 26.3886 15.0367C26.5479 14.9707 26.7187 14.9368 26.8911 14.9368C27.0636 14.9368 27.2343 14.9707 27.3937 15.0367C27.553 15.1027 27.6978 15.1995 27.8197 15.3214C27.9417 15.4434 28.0384 15.5881 28.1044 15.7474C28.1704 15.9068 28.2043 16.0775 28.2043 16.25C28.2043 16.4225 28.1704 16.5932 28.1044 16.7526C28.0384 16.9119 27.9417 17.0566 27.8197 17.1786Z"
      fill="#9CA3AF"
    />
  </svg>
</template>
