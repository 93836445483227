<template>
  <svg
    width="42"
    height="43"
    viewBox="0 0 42 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.75 25.4375V34.625C36.75 35.3212 36.4734 35.9889 35.9812 36.4812C35.4889 36.9734 34.8212 37.25 34.125 37.25H7.875C7.17881 37.25 6.51113 36.9734 6.01884 36.4812C5.52656 35.9889 5.25 35.3212 5.25 34.625V25.4375C5.25 25.0894 5.38828 24.7556 5.63442 24.5094C5.88056 24.2633 6.2144 24.125 6.5625 24.125C6.9106 24.125 7.24444 24.2633 7.49058 24.5094C7.73672 24.7556 7.875 25.0894 7.875 25.4375V34.625H34.125V25.4375C34.125 25.0894 34.2633 24.7556 34.5094 24.5094C34.7556 24.2633 35.0894 24.125 35.4375 24.125C35.7856 24.125 36.1194 24.2633 36.3656 24.5094C36.6117 24.7556 36.75 25.0894 36.75 25.4375ZM20.0714 26.3661C20.1933 26.4881 20.3381 26.5849 20.4974 26.651C20.6567 26.717 20.8275 26.751 21 26.751C21.1725 26.751 21.3433 26.717 21.5026 26.651C21.6619 26.5849 21.8067 26.4881 21.9286 26.3661L28.4911 19.8036C28.613 19.6816 28.7098 19.5369 28.7758 19.3776C28.8418 19.2182 28.8757 19.0475 28.8757 18.875C28.8757 18.7025 28.8418 18.5318 28.7758 18.3724C28.7098 18.2131 28.613 18.0684 28.4911 17.9464C28.3692 17.8245 28.2244 17.7277 28.0651 17.6617C27.9057 17.5957 27.735 17.5618 27.5625 17.5618C27.39 17.5618 27.2193 17.5957 27.0599 17.6617C26.9006 17.7277 26.7558 17.8245 26.6339 17.9464L22.3125 22.2695V7.0625C22.3125 6.7144 22.1742 6.38056 21.9281 6.13442C21.6819 5.88828 21.3481 5.75 21 5.75C20.6519 5.75 20.3181 5.88828 20.0719 6.13442C19.8258 6.38056 19.6875 6.7144 19.6875 7.0625V22.2695L15.3661 17.9464C15.1198 17.7001 14.7858 17.5618 14.4375 17.5618C14.0892 17.5618 13.7552 17.7001 13.5089 17.9464C13.2626 18.1927 13.1243 18.5267 13.1243 18.875C13.1243 19.2233 13.2626 19.5573 13.5089 19.8036L20.0714 26.3661Z"
      fill="#9CA3AF"
    />
  </svg>
</template>
