<template>
  <svg
    width="42"
    height="43"
    viewBox="0 0 42 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.8632 4.7965C38.6599 4.6209 38.4124 4.50411 38.1476 4.45872C37.8828 4.41333 37.6106 4.44106 37.3604 4.53892L4.11148 17.5507C3.64027 17.7339 3.24134 18.0653 2.97474 18.4949C2.70814 18.9245 2.5883 19.429 2.63327 19.9326C2.67825 20.4362 2.88559 20.9115 3.22409 21.2871C3.5626 21.6626 4.01393 21.918 4.51016 22.0149L13.1251 23.7063V33.3122C13.1234 33.8354 13.2789 34.3471 13.5714 34.7809C13.8639 35.2147 14.28 35.5507 14.7657 35.7452C15.2507 35.9433 15.784 35.9908 16.2963 35.8817C16.8086 35.7725 17.2763 35.5118 17.6384 35.1333L21.7925 30.825L28.3829 36.5934C28.8584 37.0152 29.4716 37.2486 30.1072 37.2497C30.3857 37.2495 30.6625 37.2057 30.9275 37.1201C31.3605 36.9827 31.75 36.7342 32.0572 36.3995C32.3643 36.0647 32.5784 35.6553 32.6781 35.212L39.3373 6.24189C39.3969 5.98062 39.3843 5.70806 39.3007 5.45344C39.2172 5.19881 39.066 4.97172 38.8632 4.7965ZM28.8324 10.7027L14.1341 21.229L5.99656 19.6327L28.8324 10.7027ZM15.7501 33.3122V25.5225L19.8172 29.0892L15.7501 33.3122ZM30.1105 34.6247L16.5458 22.7302L36.0692 8.73728L30.1105 34.6247Z"
      fill="#9CA3AF"
    />
  </svg>
</template>
