<template>
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.9183 16.6339C29.0404 16.7558 29.1372 16.9006 29.2032 17.0599C29.2693 17.2192 29.3033 17.39 29.3033 17.5625C29.3033 17.735 29.2693 17.9058 29.2032 18.0651C29.1372 18.2244 29.0404 18.3692 28.9183 18.4911L19.7308 27.6786C19.6089 27.8006 19.4642 27.8974 19.3049 27.9635C19.1455 28.0295 18.9747 28.0635 18.8022 28.0635C18.6298 28.0635 18.459 28.0295 18.2996 27.9635C18.1403 27.8974 17.9955 27.8006 17.8737 27.6786L13.9362 23.7411C13.6899 23.4948 13.5515 23.1608 13.5515 22.8125C13.5515 22.4642 13.6899 22.1302 13.9362 21.8839C14.1824 21.6376 14.5165 21.4993 14.8647 21.4993C15.213 21.4993 15.5471 21.6376 15.7933 21.8839L18.8022 24.8945L27.0612 16.6339C27.183 16.5119 27.3278 16.4151 27.4871 16.349C27.6465 16.283 27.8173 16.249 27.9897 16.249C28.1622 16.249 28.333 16.283 28.4924 16.349C28.6517 16.4151 28.7964 16.5119 28.9183 16.6339ZM37.1772 8.375V34.625C37.1772 35.3212 36.9007 35.9889 36.4084 36.4812C35.9161 36.9734 35.2484 37.25 34.5522 37.25H8.30225C7.60605 37.25 6.93837 36.9734 6.44609 36.4812C5.95381 35.9889 5.67725 35.3212 5.67725 34.625V8.375C5.67725 7.67881 5.95381 7.01113 6.44609 6.51884C6.93837 6.02656 7.60605 5.75 8.30225 5.75H34.5522C35.2484 5.75 35.9161 6.02656 36.4084 6.51884C36.9007 7.01113 37.1772 7.67881 37.1772 8.375ZM34.5522 34.625V8.375H8.30225V34.625H34.5522Z"
      fill="#9CA3AF"
    />
  </svg>
</template>
